<template>
  <v-container>
    <v-row>
      <v-col></v-col>
      <v-col>
        <v-card width="780">
          <v-form ref="form" v-model="formValid" @submit.prevent="fnAprobarCredito">
            <v-card-text>
              <div class="d-flex justify-center mt-3">
                <v-row justify="center">
                  <v-col cols="6">
                    <v-text-field v-model="codigoSap" label="Código SAP" prepend-icon="mdi-database-search"
                      :rules="[(v) => !!v || 'Código SAP es requerido']" required></v-text-field>

                    <v-select v-model="pedido" :items="itemsPedido" label="¿Tiene pedido?"
                      prepend-icon="mdi-database-search" class="mt-n1"
                      :rules="[(v) => v !== null || 'Seleccione una opción']" required></v-select>

                    <v-textarea ref="comentario" v-model="comentario" label="Agregar comentario" auto-grow outlined
                      rows="4" row-height="25" :rules="[(v) => !!v || 'Comentario es requerido']" required></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="#031D6A" class="px-5 white--text mr-2" :disabled="fnCheckIfFormularioLleno">
                APROBAR CRÉDITO
                <v-icon class="ml-2">mdi-check</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  props: ['detalleEvaluacion'],
  data() {
    return {
      formValid: false,
      codigoSap: null,
      pedido: null,
      comentario: '',
      itemsPedido: [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 },
      ],
    };
  },
  computed: {
    fnCheckIfFormularioLleno() {
      if (this.pedido != null && this.comentario) {
        return false
      } else {
        return true
      }

    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
  watch: {
    detalleEvaluacion: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        setTimeout(() => {
          this.pedido = newValue.flag_tiene_pedido;
          this.codigoSap = newValue.codigo_sap;  
        }, 500);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    async fnAprobarCredito() {
      if (this.$refs.form.validate()) {
        try {
          await this.patchGestiones([
            this.detalleEvaluacion.id,
            {
              flag_precalificacion_concluidas: 1,
              flag_precalificacion_concluidas_fecha: moment()
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
              flag_precalificacion_concluidas_comentario: this.comentario,
              codigo_sap: this.codigoSap,
              flag_tiene_pedido: this.pedido,
              flag_precalificacion_concluidas_por_usuario: this.currentUser.usuario,
              flag_precalificacion_concluidas_por_usuario_id: this.currentUser.id,
            }
          ]).then(() => {
            this.$notify({
              title: 'Aprobado',
              text: 'Crédito aprobado exitosamente.',
              color: '#2E7D32',
              timeout: 3000,
            });

            setTimeout(() => {
              this.$router.push('/');
            }, 350);

          });

          // You might want to emit an event or update the parent component
          this.$emit('creditoAprobado');
        } catch (error) {

          this.$notify({
            title: 'Error',
            text: 'Error al aprobar crédito. Por favor, intente nuevamente.',
            color: '#EF5350',
            timeout: 3000,
          });


        }
      }
    },
  },
};
</script>